<template>
  <div class="text-editor-container">
    <div class="text-editor-header">
      <div class="left">
        <div class="ic"></div>
        <p class="name">{{ currentData.name }}</p>
        <p class="updata-time"><i class="iconfont iconwangpan-lishijilu1x" />最近保存 {{ updataTime }}</p>
      </div>
      <div class="right">
        <el-button @click.stop="handleBack">返回</el-button>
        <el-button type="primary" :loading="btnLoading" @click.stop="handleSave">保存</el-button>
      </div>
    </div>
    <section class="text-editor-warp">
      <Editor v-model="text"/>
    </section>
  </div>
</template>
<script>
import Editor from '@/manage-views/views/knowledgeBase/components/richText/Editor.vue'
import { defineComponent, ref, computed } from 'vue'
import { knowledgePage } from '@/saas-apis/konwledgeBase.js'
import { Message } from 'element-ui'
import Router from '@/router'
export default defineComponent({
  components: {
    Editor
  },
  setup() {
    const dataLoading = ref(false)
    const currentData = ref({})
    const getData = () => {
      const reqData = {
        id: Router.currentRoute.params.id,
      }
      dataLoading.value = true
      knowledgePage.details(reqData).then(res => {
        if(res.data.code === 200) {
          currentData.value = res.data.data
          text.value = res.data.data?.details?.content || ''
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        dataLoading.value = false
      })
    }
    const updataTime = computed(() => {
      let time = ''
      if(currentData.value && currentData.value.detail) {
        time = currentData.value.detail.updated_at || currentData.value.detail.created_at || '_'
      } else {
        time = '-'
      }
      return time
    })
    getData()
    const text = ref('')
    const btnLoading = ref(false)
    const handleSave = () => {
      const reqData = {
        id: Router.currentRoute.params.id,
        name: currentData.value.name,
        detail: {
          content: text.value
        }
      }
      btnLoading.value = true
      knowledgePage.put(reqData).then(res => {
        if(res.data.code === 200) {
          Message.success(res.data.msg)
        } else {
          Message.error(res.data.msg)
        }
      }).catch((res) => {
        Message.error(res.data.msg)
      }).finally(() => {
        btnLoading.value = false
      })
    }
    const handleBack = () => {
      Router.back()
    }
    return {
      text,
      handleSave,
      updataTime,
      currentData,
      handleBack,
      btnLoading
    }
  },
})
</script>
<style lang="less" scoped>
.text-editor-container{
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #fff;
  .text-editor-header{
    height: 50px;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    .left{
      display: flex;
      align-items: center;
      .ic{
        height: 26px;
        width: 6px;
        box-sizing: border-box;
        background: #409eff;
        border-radius: 3px;
        margin-right: 16px;
      }
      .name{
        font-size: 1.3em;
        font-weight: 600;
      }
      .updata-time{
        margin-left: 24px;
        color: #999;
      }
    }
    .right{
      display: flex;
      align-items: center;
    }
  }
  .text-editor-warp{
    flex-grow: 1;
    height: 10px;
  }
}
</style>
